import * as React from 'react'
import { SP, PC } from '../components/utils/media-query'
import Header from '../components/organisms/Header'
import TitleWithText from '../components/atoms/TitleWithText'
import Button from '../components/atoms/TheButton'
import Footer from '../components/organisms/Footer'
import HeadingIllust from '../components/atoms/HeadingIlust'

const styles = require('scss/aboutllc.module.scss')
const AboutLLC = () => {
  const spImage = require('images/sp/about_llc_0.png')
  const pcImage = require('images/pc/about_llc_0.png')
  const title = '合同会社とは'
  const pageName = 'About Limited Liability Company'
  const pcTitleNodes = [
    ,
    //  1
    //  2
    <div>
      合同会社とは、スピード対応に特化した組織形態です。
    </div>,
    //  3
    <div>迅速に・丁寧に対応させていただきます。</div>,
  ]
  const spTitleNodes = [
    ,
    //  2
    <div className="mb-4">
      合同会社とは、
      <br />
      スピード対応に特化した組織形態です。
    </div>,
    //  3
    <div>迅速に・丁寧に対応させていただきます。</div>,
  ]
  const pcTextNodes = [
    //  1
    <div>
      よく寄せられるお問い合わせに
      <br />
      「合同会社に任せるのが不安」「そもそも合同会社ってなに？」というお声がございます。
      <br />
      しかし、開発やご契約には一切の影響はございませんのでご安心ください。
    </div>,
    //  2
    <div>
      合同会社とは、株式会社と比べて法律上の制約が少なく、スピード感を持って組織運営ができる企業です。
      <br />
      現代的な会社形態で、他にはアマゾンジャパン合同会社、Apple
      Japan合同会社、グーグル合同会社など、
      <br />
      先端企業でも取り入れている組織形態となっています。
    </div>,
    //  3
    <div>
      よりスピーディに対応させていただけるのも、合同会社のメリットの一つです。
      <br />
      実際に弊社をご利用いただいた方々からも、「小回りの利いた開発対応のおかげで、想定よりも早い時間に納品された」
      <br />
      「他の会社ではあった無駄なやり取りがカットされ依頼しやすかった」といったお声をいただいています。
    </div>,
  ]

  const spTextNodes = [
    //  1
    <div>
      よく寄せられるお問い合わせに「合同会社に任せるのが不安」「そもそも合同会社ってなに？」というお声がございます。しかし、開発やご契約には一切の影響はございませんのでご安心ください。
    </div>,
    //  2
    <div>
      合同会社とは、株式会社と比べて法律上の制約が少なく、
      <br />
      スピード感を持って組織運営ができる企業です。
      <br />
      現代的な会社形態で、他にはアマゾンジャパン合同会社、Apple
      Japan合同会社、グーグル合同会社など、
      <br />
      先端企業でも取り入れている組織形態となっています。
    </div>,
    //  3
    <div>
      よりスピーディに対応させていただけるのも、合同会社のメリットの一つです。実際に弊社をご利用いただいた方々からも、「小回りの利いた開発対応のおかげで、想定よりも早い時間に納品された」「他の会社ではあった無駄なやり取りがカットされ依頼しやすかった」といったお声をいただいています。
    </div>,
  ]

  return (
    <section className={styles.wrapper}>
      <Header />
      <div>
        <SP>
          <HeadingIllust
            image={spImage}
            title={title}
            pageName={pageName}
            top={-8}
          />
          {Array(3)
            .fill(null)
            .map((_, index) => {
              return (
                <div
                  className={styles[`content${index + 1}`]}
                  key={`content-${index}`}
                >
                  <TitleWithText
                    titleNode={spTitleNodes[index]}
                    textNode={spTextNodes[index]}
                  />
                </div>
              )
            })}
          <div className={styles.buttonWrapper}>
            <Button
              elevation="1"
              icon
              to="/#contact"
              size="responsive"
            >
              ご相談はこちら
            </Button>
          </div>
        </SP>
        <PC>
          <HeadingIllust
            image={pcImage}
            title={title}
            pageName={pageName}
            top={-12}
          />
          {Array(3)
            .fill(null)
            .map((_, index) => {
              return (
                <div
                  className={styles[`content${index + 1}`]}
                  key={`content-${index}`}
                >
                  <TitleWithText
                    titleNode={pcTitleNodes[index]}
                    textNode={pcTextNodes[index]}
                  />
                </div>
              )
            })}
          <div className={styles.buttonWrapper}>
            <Button
              type="square"
              elevation="1"
              icon
              to="/#contact"
            >
              詳細・お見積り
            </Button>
          </div>
        </PC>
      </div>
      <Footer />
    </section>
  )
}
export default AboutLLC
